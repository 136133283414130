import backgroundImage from "./images/bg.jpg";

import "./App.css"
import React from "react"
import Stories from "./components/Stories"


export const baseUrl = "https://api.storieswall.com";

const App = () => {
    console.log("App");

    return (
        <div className="safe-zone">         
			<img className="image-background" src={backgroundImage} alt="background" />
            <Stories />
        </div>
    );
};

export default App;